.modal-main-content-Ai-software {
  .modal-header {
    border: none;
    button {
      right: 1.2rem !important;
      top: 0.5rem !important;
      font-size: 12px;
    }
  }
  .modal-body {
    padding: 0rem 2rem 2rem 2rem;
    border: none;
    .selected-input {
      border-radius: 0.375rem;
      border: 1px solid #dee2e6;
      div {
        border: none;
      }
    }
  }
}

.cancel-button {
    border: 1px solid #5664d2;
    padding: 6px 14px;
    border-radius: 6px ;
  background: #fff  ;
  color: #5664D2;
}

.done-button{
    border: 1px solid #5664d2;
    padding: 6px 14px;
    border-radius: 6px ;
    background: #5664D2 ;
    color: #fff ;
}