.platform-updates-page {
  min-height: 650px;

  .page-title {
    font-size: 20px;
    color: #1a2052;
    font-weight: 800;
    margin: 0 10px;
    line-height: 47px;
  }

  .page-body {
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 35px;

    .alert-wrapper {
      padding: 20px 30px 0;
    }
  }
  .card-list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 16px;
	
	@media (max-width: 991px) {
			  grid-template-columns: repeat(3, 1fr);		
	}

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
 
	.card {
		padding: 0;
		.card-img {
			width: 100%;
			height: 200px;
      object-fit: fill;
		}
    }
  }
}
