.nav-tabs{
    &.custom-style-1{
        display: inline-flex;
        background-color: #fff;
        border: none;

        .nav-item{
            background-color: #D1D2D6; 
        }

        .nav-item:has(+.active){
            border-bottom-right-radius: 8px;
            .nav-link{
                border-bottom-right-radius: 8px;
            }
        }

        .nav-item:has(> .active) + .nav-item{
            border-bottom-left-radius: 8px;
            .nav-link{
                border-bottom-left-radius: 8px;
            }
        }

        .nav-link{
            font-size: font-sizes(common, large);
            font-weight: 400;
            color: color(black, 1);
            border: none;
            border-radius: 0px;
            padding: 23px !important;
            
            &.active{
                font-weight: 600;
            }
        }

        // @media (min-width: 1201px) {
        //     .nav-item a{
        //         background-color: purple;
        //     }
        // }

        // @media (max-width: 1200px) {
        //     .nav-item a{
        //         background-color: yellow;
        //     }
        // }
        
        // @media (max-width: 1024px) {
        //     .nav-item a{
        //         background-color: green;
        //     }
        // }

        // @media (max-width: 768px) {
        //     .nav-item a{
        //         background-color: blue;
        //     }
        // }

        // @media (max-width: 480px) {
        //     .nav-item a{
        //         background-color: red;
        //     }
        // }
    }
}