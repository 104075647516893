$main-bg-color          :   #f3f3f3;

$font-family            :   'Inter', sans-serif;

$font-weight-lighter    :   lighter;
$font-weight-light      :   400;
$font-weight-normal     :   600;
$font-weight-bold       :   700;
$font-weight-bolder     :   bolder;

$font-size-xs   :   11px;
$font-size-sm   :   12px;
$font-size-md   :   16px;
$font-size-lg   :   18px;

$font-color-black   :   #000000;

$font-size-base     :   $font-size-md;
$font-color-base    :   $font-color-black;
$font-weight-base   :   $font-weight-light;

$box-shadow-color   :   #00000040;

$labels-font-color  :   $font-color-base;
$labels-font-size   :   $font-size-md;
$labels-font-weight :   $font-weight-light;

$questionnaire-headers-font-color   :   $font-color-base;
$questionnaire-headers-font-size    :   $font-size-md;
$questionnaire-headers-font-weight  :   $font-weight-normal;

$btn-custom-padding     :   7px 14px 7px 14px;
$btn-custom-radius      :   7px;
$btn-custom-font-weight :   $font-weight-normal;
$btn-custom-font-size   :   13px;

