.attachments-list {
    padding: 8px 4px 8px 4px;
    border: 1px solid #f1f5f7;
    margin-top: 12px;
}

.attachments-list .delete-attachment {
    margin-left: 14px;
    cursor: pointer;
}

.text-answers {
    border: 1px solid #eaeaea;
    background: #f1f5f7;
    padding: 8px 4px;
    border-radius: 4px;
}

#page-header-notifications-dropdown .noti-dot{
    top: 14px;
    right: 12px;
    padding: 4px 4px 2px 4px;
    font-size: 10px;
    width: auto;
    height: auto;
}

.chat-messages-spinner-loading{
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 74px;
}

.chatbox-container {
    position: fixed;
    right: 16px;
    bottom: 36px;
    z-index: 1000;
}

.chatbox-container > button {
    border-radius: 100%;
    padding: 14px;
    line-height: 1;
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 1000;
}

.chatbox-container > button i {
    font-size: 1.5rem;
}
.card-loading-container {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #ffffff80;
    z-index: 10;
}
.delete-chat-btn {
    position: absolute;
    right: 12px;
    font-size: 1.1rem;
    line-height: 1;
    bottom: 8px;
    cursor: pointer;
}