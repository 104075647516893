.questionnaire-form{
    .tooltip-container{
        .help-text-icon {
            color: #899ba1;
        }
    }
}

.section-nav{
    .nav-item {
        .nav-link{
            padding: 12px 14px 12px 2px !important;
            &.active{
                background: transparent !important;
                border-bottom: 1px solid var(--bs-nav-pills-link-active-bg);
                border-radius: 0px;
                color: var(--bs-nav-pills-link-active-bg);
            }
        }
    }
}

.sub-section {
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 14px 12px;
    margin-bottom: 20px;

    .sub-section-title {
        border-bottom: 1px solid #eee;
        margin-bottom: 12px;
    }
    .sub-section-items {
        padding: 0px 0px 0px 16px;
    }
}