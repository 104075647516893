._loading_overlay_wrapper{
    ._loading_overlay_overlay{
        bottom: 38px;
        top: auto;
        margin: 0px;
        background: transparent;

        ._loading_overlay_content{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            font-size: 12px;
            font-weight: 600;
            margin-top: 16px;
            color: #1A1D30;
        }
    }
}

.tpdd-table{
    
    thead{
        th{
            font-weight: 600;
            font-size: 12px;
            color: #4F4F4F;
            background-color: #fff;
            border: none;
        }
    }
    tbody{
        td{
            font-weight: 400;
            font-size: 12px;
            color: #1A1D30;
            background-color: #fff;
            border: none;
        }
    }
}