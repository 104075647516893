.new-thirdparty-page{
  .table-container {
    width: 100%;
    margin: auto;

    border: 1px rgba(137, 155, 161, 1) solid;
    border-radius: 12px;
    margin-bottom: 20px;

    table {
      width: 100%;
      border-collapse: separate; /* Disable collapsing borders */
      border-spacing: 0; /* Remove spacing between cells */

      thead{
        td{
          font-size: 12px;
          font-weight: 600;
          color: color(grey, 2);
        }
      }

      tbody{
        td:last-child {
          font-size: 21px;
        }
      }
      

      th, td {
        text-align: left;
        padding: 8px;
      }
    }

    .edit-btn, .delete-btn {
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .confirm-form-sections-container{
    .section-header{
      .edit-section-btn{
        border: none;
        font-size: 22px;
        color : color(black, 1);
      }
    }
  }

  .actions-buttons-container{
    .btn{
      min-width: 195px;
    }
  }
}