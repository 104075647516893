.third-party-home-page {
  height: 1009px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 70px;
  padding: 86px 0px;
  overflow-x: hidden;
  position: relative;

  h1 {
    font-size: 40px;
    margin-top: 100px;
    font-weight: 800;
    color: #000;
  }

  .main-container {
    position: absolute;
    bottom: 86px;
    display: flex;
    width: 100%;
    gap: 30px;

  }

  .new-questionnaire-box,
  .existing-questionnaire-box {
    width: 50%;
    height: 445px;
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 4px 25px 0px rgba(255, 255, 255, 0.5);
    padding: 23px;
    gap: 62px;
    transition: width 0.8s ease-in-out;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    cursor: pointer;
    color: #000000;

    a,
    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button {
      width: 180px;
      height: 43px;
      box-shadow: 0px 4px 4px 0px #00000040;
      border-radius: 7px;
      font-size: 25px;
      font-weight: 900;
    }
  }

  .new-questionnaire-box {
    border-radius: 0px 300px 300px 0px;
  }

  .existing-questionnaire-box {
    border-radius: 300px 0px 0px 300px;
  }

  .existing-questionnaire-box:hover,
  .new-questionnaire-box:hover {
    width: 85%;
  }
}
