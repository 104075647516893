.report-details-page{
    .accordion{
        &.questionnaire{
            .accordion-item{
                margin-bottom: 16px;
                border: none;
                border-radius: 8px !important;
                overflow: hidden;
                box-shadow: 0px 4px 4px 0px #00000040;
                position: relative;

                &.active{
                    border: none;
                    .accordion-header{
                        button{
                            box-shadow: none;
                            background: #F5F6FF;
                        }
                    }
                }

                .accordion-header{
                    background-color: #F5F6FF;
                    
                    button{
                        color: #000;
                        font-weight: bold;
                        padding: 16px;
                        height: 92px;
                        border-radius: 8px;
        
                        > * {
                            position: relative;
                            z-index: 1;
                            background: transparent;
                            color :  #000000;
                            font-size: 16px;
                            font-weight: 600;
                        }
        
                        img{
                            width: 50px;
                            margin-right: 12px;
                        }
                        
                    }
                }

                .accordion-collapse {
                    background-color: #F5F6FF;
                }

                .accordion-body{
                    padding: 0px 80px 0px 80px; 

                    .question-title{
                        font-weight: 400;
                    }

                    .sub-question-container{
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        flex-direction: row;
                        margin-top: 12px;
                        padding: 0px 0px;
                        margin-left: 15px;
                        margin-bottom: 15px;

                        .question{
                            flex-basis: 50% !important;
                            flex: 2 1;
                        }
                    }

                    .files-container{
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        flex-direction: row;

                        .file-preview{
                            display: flex;
                            flex-flow: column;
                            justify-content: center;
                            align-items: start;
                            flex-basis: 33.33%;
                            flex: 2 1;
                        }
                    }
                }
                
                .form-control {
                    background: transparent;
                }
        
                label {
                    color : 400;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }
}