$primary    :   (
    0   :   #F5F6FF,
    100 :   #CBD1FC,
    200 :   #A3ADF7,
    300 :   #828EEF,
    400 :   #6775E4,
    500 :   #5664D2,
    600 :   #414FBD,
    700 :   #3340A3,
    800 :   #293487
);

$black  :   (
    1   :   #000000,
    2   :   #1D1D1D,
    3   :   #282828
);

$grey   :   (
    1   :   #333333,
    2   :   #4F4F4F,
    3   :   #828282,
    4   :   #BDBDBD,
    5   :   #E0E0E0
);

$white  :   (
    1   :   #FFFFFF
);

$palettes   :   (
    black   :   $black,
    grey    :   $grey,
    white   :   $white,
    primary :   $primary
);

@function color($color, $tone){

    @warn map-get($palettes, $color);

    @if map-has-key($palettes, $color){
        $color: map-get($palettes, $color);

            @if map-has-key($color, $tone){
                $tone: map-get($color, $tone);
                @return $tone;
            }

            @warn "unknown tone `#{$tone}` in color";
            @return null;        
    }

    @warn "unknown color `#{$color}` in palette";
    @return null;
}