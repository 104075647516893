//Import Icon scss
@import "../scss/icons.scss";

//Import Dark theme
// @import "../scss/bootstrap-dark.scss";
// @import "../scss/app-dark.scss";

//import Light Theme
@import "../scss/bootstrap.scss";
@import "../scss/app.scss";

// @import "../scss/custom/ltr/bootstrap-rtl";
// @import "../scss/custom/ltr/components-rtl";
// @import "../scss/custom/ltr/float-rtl";
// @import "../scss/custom/ltr/general-rtl";
// @import "../scss/custom/ltr/pages-rtl";
// @import "../scss/custom/ltr/plugins-rtl";
// @import "../scss/custom/ltr/spacing-rtl";
// @import "../scss/custom/ltr/structure-rtl";
// @import "../scss/custom/ltr/text-rtl";

html {
  // LTR styles
  &.direction-ltr {
    direction: ltr;

    body {
      * {
        outline: none;
      }
    }

    .accordian-bg-products {
      background-color: $gray-300;
    }

    .nav-link {
      cursor: pointer;
    }

    .css-4uzw5i {
      display: flex;
      width: 32px;
      height: 32px;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: center;
      justify-content: center;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: var(
        --ds-shadow-raised,
        0 1px 1px rgba(9, 30, 66, 0.25),
        0 0 1px 1px rgba(9, 30, 66, 0.13)
      );
    }

    .rdw-suggestion-dropdown {
      width: 320px;
      line-height: 35px;
      background: var(--ds-surface-overlay, #ffffff);
      border-radius: 3px;
      box-shadow: var(
        --ds-shadow-overlay,
        0 0 1px rgba(9, 30, 66, 0.31),
        0 4px 8px -2px rgba(9, 30, 66, 0.25)
      );
      padding: 4px 0px;
      width: 320px;
      max-height: 380px;
      color: #313131;
    }

    .rdw-suggestion-dropdown > .rdw-suggestion-option:hover {
      background-color: #e6e6e6;
      cursor: pointer;
    }

    .introText p:empty:before {
      content: " ";
      white-space: pre;
    }

    .switch-to-modules {
      display: flex;
      flex-wrap: wrap;
      font-size: 17px;
      text-align: center;
    }

    .switch-to-modules-item-left {
      padding: 5px;
      flex: 50%;
      text-align: start;
    }

    .switch-to-modules-item-right {
      padding: 5px;
      flex: 50%;
      text-align: end;
    }

    /* Responsive layout - makes a one column-layout instead of a two-column layout */
    @media (max-width: 800px) {
      .switch-to-modules-item-right,
      .switch-to-modules-item-left {
        flex: 100%;
      }
    }

    .dataTables_filter,
    .dataTables_paginate {
      float: right;
      .pagination {
        .page-link {
          border-radius: 30px !important;
          margin: 0 3px;
          border: none;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .rdw-editor-main {
      border: 1px solid $gray-300;
      height: 239px;
    }
    .dz-message {
      text-align: center;
      padding: 0px;
      margin: 2em 0;
    }

    .react-datepicker-wrapper {
      width: 100% !important;
    }

    .ReactModal__Overlay {
      z-index: 1001 !important;
    }

    .rangeslider__fill {
      background-color: $primary !important;
    }

    .react-bootstrap-table {
      padding-top: 0px !important;
    }

    .square-switch {
      .react-switch-bg {
        border-radius: 5px !important;
      }
    }

    input[type="range"] {
      -webkit-appearance: auto !important;
    }

    .chat-conversation-height {
      min-height: 500px;
    }

    .expand-cell {
      cursor: pointer !important;
    }

    //modal close icon
    .close {
      background: transparent;
      border: 0;
      font-size: 10px;
      padding: 1.35rem 1.25rem;
      background: transparent escape-svg($btn-close-bg-dark) center /
        $btn-close-width auto no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.5;
      width: 1em;
      height: 1em;
      z-index: 2;
      span {
        display: none;
      }
    }

    // kanban board
    .react-kanban-column {
      padding: 15px;
      border-radius: 2px;
      background-color: rgb(238, 238, 238);
      margin: 5px;
      vertical-align: top;
    }
    .fcYNFc,
    .sc-AxmLO.gmtmqV {
      background-color: $card-bg !important;
      margin: 0 20px 0 0 !important;
    }
    .react-kanban-card-adder-button {
      width: 100%;
      margin-top: 5px;
      background-color: transparent;
      cursor: pointer;
      border: 1px solid rgb(204, 204, 204);
      transition: all 0.3s ease 0s;
      border-radius: 3px;
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    .react-kanban-card-adder-form {
      box-sizing: border-box;
      max-width: 250px;
      min-width: 250px;
      border-radius: 3px;
      background-color: rgb(255, 255, 255);
      padding: 10px;
      margin-bottom: 7px;
    }

    // calendar
    .fc-daygrid-event {
      color: $white !important;
    }
    .card-columns .card {
      margin-bottom: 24px;
    }

    @media (min-width: 576px) {
      .card-columns {
        -webkit-column-count: 3;
        column-count: 3;
        grid-column-gap: 1.25rem;
        -webkit-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
      }
      .card-columns .card {
        display: inline-block;
        width: 100%;
      }
    }
    .react-bootstrap-table-pagination {
      --bs-gutter-x: 0;
    }

    .react-bootstrap-table {
      th,
      td {
        padding: 0.75rem 0.4rem;
      }
      .react-bootstrap-table-sort-order,
      .order {
        float: none;
      }
      th.sortable {
        cursor: pointer;
      }
      th .order:before {
        margin-left: 3.5px;
        content: "\2191";
        opacity: 0.4;
      }
      th .order:after {
        content: "\2193";
        opacity: 0.4;
      }

      th .react-bootstrap-table-sort-order:before {
        content: "\2191";
        opacity: 0.4;
      }

      th .react-bootstrap-table-sort-order:after {
        content: "\2193";
        opacity: 1;
      }

      th .react-bootstrap-table-sort-order.dropup:before {
        opacity: 1;
        content: "\2191";
      }

      th .react-bootstrap-table-sort-order.dropup:after {
        content: "\2193";
        opacity: 0.4;
      }
    }

    // SPIK-726
    .select--filter-categories .select2-selection__control {
      height: 30px;
      overflow-y: auto;
    }
    .select--filter-categories .select2-selection__indicators {
      top: 0;
      height: 37px;
      position: sticky;
      align-items: start;
    }

    //

    .dissco-risks-matrix {
      font-size: 12px;
      position: relative;
      padding-left: 51px;
      padding-top: 41px;

      // min-width: 800px;
      overflow-x: auto;

      & > div {
        // min-width: 800px;
        // overflow-x: auto;
      }

      .table > :not(caption) > * > * {
        background-color: inherit;
      }

      &:before {
        color: #505d69;
        top: 0;
        // left: 131.5px;
        left: 21.7%;
        height: 40px;
        right: 0;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        content: "Potential damage";
        position: absolute;
        background-color: #f0f1ff;
      }

      &:after {
        color: #505d69;
        left: 0;
        bottom: 0;
        line-height: 40px;
        text-align: center;
        transform: rotate(-90deg) translate(71.4%, -245%);
        content: "Likelihood";
        width: 258px;
        // height: 40px;
        // height: 10%;
        background-color: #f0f1ff;
        position: absolute;
        z-index: 1;
        border-bottom: 1px solid #fff;
      }

      tr {
        td {
          height: 51px;
          line-height: 51px;
          border: 1px solid #fff !important;
          text-align: center !important;
          padding: 0;

          &.dissco-risks-matrix__label {
            position: relative;
            background-color: #f0f1ff;

            .sequence-var {
              height: 81px;
              display: block;
            }
            .sequence-val {
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              position: absolute;
              text-align: center;
            }
          }
          &:not(.dissco-risks-matrix__label) {
            color: #fff !important;
          }
        }

        &:nth-child(2) {
          & > td {
            &:nth-child(3) {
              background-color: #ffa049 !important;
            }
            &:nth-child(4),
            &:nth-child(5) {
              background-color: #ff4949 !important;
            }
            &:nth-child(6),
            &:nth-child(7) {
              background-color: #822626 !important;
            }
          }
        }
        &:nth-child(3) {
          & > td {
            &:nth-child(3) {
              background-color: #59c94f !important;
            }
            &:nth-child(4) {
              background-color: #ffa049 !important;
            }
            &:nth-child(5),
            &:nth-child(6) {
              background-color: #ff4949 !important;
            }
            &:nth-child(7) {
              background-color: #822626 !important;
            }
          }
        }
        &:nth-child(4) {
          & > td {
            &:nth-child(3),
            &:nth-child(4) {
              background-color: #59c94f !important;
            }

            &:nth-child(5) {
              background-color: #ffa049 !important;
            }
            &:nth-child(6),
            &:nth-child(7) {
              background-color: #ff4949 !important;
            }
          }
        }
        &:nth-child(5) {
          & > td {
            &:nth-child(3) {
              background-color: #4eb8b1 !important;
            }
            &:nth-child(4),
            &:nth-child(5) {
              background-color: #59c94f !important;
            }
            &:nth-child(6) {
              background-color: #ffa049 !important;
            }
            &:nth-child(7) {
              background-color: #ff4949 !important;
            }
          }
        }
        &:nth-child(6) {
          & > td {
            &:nth-child(3),
            &:nth-child(4) {
              background-color: #4eb8b1 !important;
            }
            &:nth-child(5),
            &:nth-child(6) {
              background-color: #59c94f !important;
            }
            &:nth-child(7) {
              background-color: #ffa049 !important;
            }
          }
        }
      }
    }

    //

    @media (max-width: 992px) {
      .dissco-special-button-for-create-risk {
        width: 100% !important;
        height: 36px;
        margin-top: 20px;
      }
    }

    //

    .dissco-table {
      font-size: 12px;
      position: relative;

      tr {
        height: 80px;

        th,
        td {
          text-align: center;
          box-shadow: none !important;
          border: 0 !important;

          border-right: 1px solid #fff !important;
          border-bottom: 1px solid #fff !important;

          &:first-child {
            padding-left: 45px;
          }
          &:last-child {
            padding-right: 30px;
          }
          &.sortable {
            display: flex;

            & > span {
              font-size: 10px;
              float: inherit !important;
              padding: 0 10px !important;
            }
          }
        }
      }
      thead {
        tr {
          padding: 0;
          line-height: 80px;
        }
      }
      tbody {
        tr {
          height: 80px;

          &:nth-child(odd) {
            background-color: #fafaff !important;
          }
          &:nth-child(even) {
            background-color: #fff !important;
          }
        }
      }
    }

    //

    .nav-link {
      padding: 12px 20px !important;
      font-size: 14px !important;
    }

    // SPIK-1164, tables
    @media (max-width: 768px) {
      table {
        font-size: 12px;
      }
      .inner-custom-pagination {
        padding-top: 30px;
      }
    }

    // .nav-tabs-custom {
    // 	span {
    // 		// white-space: nowrap;
    // 	}

    // 	.nav-link {
    // 		padding: 0;
    // 		display: flex;
    // 		align-items: center;
    // 		justify-content: center;
    // 	}

    // 	@media (min-width: 1200px) {

    // 	}
    // }

    // icon colors
    .notification-item {
      .avatar-xs {
        i {
          color: #fff;
        }
      }
    }

    // fix drag & drop button bugs in Firefox.
    .btn--drag-and-drop {
      position: relative;

      i {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
      }
    }

    // fix tabs active link colors
    #v-pills-tab {
      a,
      .active {
        color: #fff !important;
      }
    }

    //
    .dissco-latest-cases {
      #pageDropDown {
        display: none !important;
      }
    }

    // spinner color
    .btn .spinner-border {
      color: #fff !important;
    }
  }

  // RTL styles
  &.direction-rtl {
    direction: rtl;

    @import "../scss/custom/rtl/bootstrap-rtl";
    @import "../scss/custom/rtl/components-rtl";
    @import "../scss/custom/rtl/float-rtl";
    @import "../scss/custom/rtl/general-rtl";
    @import "../scss/custom/rtl/pages-rtl";
    @import "../scss/custom/rtl/plugins-rtl";
    @import "../scss/custom/rtl/spacing-rtl";
    @import "../scss/custom/rtl/structure-rtl";
    @import "../scss/custom/rtl/text-rtl";

    body {
      * {
        outline: none;
      }
    }

    input {
      direction: ltr;
    }

    .sip-phone-input {
      direction: ltr;
    }

    .accordian-bg-products {
      background-color: $gray-300;
    }

    .nav-link {
      cursor: pointer;
    }

    .dataTables_filter,
    .dataTables_paginate {
      float: right;
      .pagination {
        .page-link {
          border-radius: 30px !important;
          margin: 0 3px;
          border: none;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .rdw-editor-main {
      border: 1px solid $gray-300;
      height: 239px;
    }
    .dz-message {
      text-align: center;
      padding: 0px;
      margin: 2em 0;
    }

    .react-datepicker-wrapper {
      width: 100% !important;
    }

    .ReactModal__Overlay {
      z-index: 1001 !important;
    }

    .rangeslider__fill {
      background-color: $primary !important;
    }

    .react-bootstrap-table {
      padding-top: 0px !important;
    }

    .square-switch {
      .react-switch-bg {
        border-radius: 5px !important;
      }
    }

    input[type="range"] {
      -webkit-appearance: auto !important;
    }

    .chat-conversation-height {
      min-height: 500px;
    }

    .expand-cell {
      cursor: pointer !important;
    }

    //modal close icon
    .close {
      background: transparent;
      border: 0;
      font-size: 10px;
      padding: 1.35rem 1.25rem;
      background: transparent escape-svg($btn-close-bg-dark) center /
        $btn-close-width auto no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0.5;
      width: 1em;
      height: 1em;
      z-index: 2;
      span {
        display: none;
      }
    }

    // kanban board
    .react-kanban-column {
      padding: 15px;
      border-radius: 2px;
      background-color: rgb(238, 238, 238);
      margin: 5px;
      vertical-align: top;
    }
    .fcYNFc,
    .sc-AxmLO.gmtmqV {
      background-color: $card-bg !important;
      margin: 0 20px 0 0 !important;
    }
    .react-kanban-card-adder-button {
      width: 100%;
      margin-top: 5px;
      background-color: transparent;
      cursor: pointer;
      border: 1px solid rgb(204, 204, 204);
      transition: all 0.3s ease 0s;
      border-radius: 3px;
      font-size: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    .react-kanban-card-adder-form {
      box-sizing: border-box;
      max-width: 250px;
      min-width: 250px;
      border-radius: 3px;
      background-color: rgb(255, 255, 255);
      padding: 10px;
      margin-bottom: 7px;
    }

    // calendar
    .fc-daygrid-event {
      color: $white !important;
    }
    .card-columns .card {
      margin-bottom: 24px;
    }

    @media (min-width: 576px) {
      .card-columns {
        -webkit-column-count: 3;
        column-count: 3;
        grid-column-gap: 1.25rem;
        -webkit-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
      }
      .card-columns .card {
        display: inline-block;
        width: 100%;
      }
    }
    .react-bootstrap-table-pagination {
      --bs-gutter-x: 0;
    }

    .react-bootstrap-table {
      th,
      td {
        padding: 0.75rem 0.4rem;
      }
      .react-bootstrap-table-sort-order,
      .order {
        float: right;
      }
      th.sortable {
        cursor: pointer;
      }
      th .order:before {
        margin-left: 3.5px;
        content: "\2191";
        opacity: 0.4;
      }
      th .order:after {
        content: "\2193";
        opacity: 0.4;
      }

      th .react-bootstrap-table-sort-order:before {
        content: "\2191";
        opacity: 0.4;
      }

      th .react-bootstrap-table-sort-order:after {
        content: "\2193";
        opacity: 1;
      }

      th .react-bootstrap-table-sort-order.dropup:before {
        opacity: 1;
        content: "\2191";
      }

      th .react-bootstrap-table-sort-order.dropup:after {
        content: "\2193";
        opacity: 0.4;
      }
    }

    // SPIK-726
    .select--filter-categories .select2-selection__control {
      height: 30px;
      overflow-y: auto;
    }
    .select--filter-categories .select2-selection__indicators {
      top: 0;
      height: 37px;
      position: sticky;
      align-items: start;
    }
  }
}
