:root {
  --color-body: #ffffff;
  --color-primary: #5664d2;
  --color-primary--hover: color-mix(in srgb, var(--color-primary) 85%, #000);

  --color-secondary: #252b3b;
  --color-secondary--hover: var(--color-secondary);

  --navbar-primary-brand-size: 25px;
  --navbar-secondary-brand-size: 25px;

  --primary-font: "Inter", sans-serif;

  --bs-primary-rgb: var(--color-primary);
}

/**
 * Logo size settings
 */
#page-topbar .navbar-brand-box .logo img {
  width: auto;
  height: var(--navbar-secondary-brand-size);
}

.authentication-page-content .text-center > a > img {
  width: auto !important;
  max-width: 100% !important;
  height: var(--navbar-primary-brand-size);
}

#sidebar-menu ul li ul.sub-menu li a {
  color: #fff !important;
  opacity: 0.75;
}

/* 
 * Colors
 */
a,
.btn-link,
.text-primary,
.nav-link:hover,
.nav-link:focus,
.text-primary,
/* .nav-pills .nav-link.active, */
.react-kanban-board a,
.nav-tabs-custom .nav-item .nav-link.active,
.twitter-bs-wizard .twitter-bs-wizard-nav .step-number {
  color: var(--color-primary);
}
.avatar-title {
  color: var(--color-primary) !important;
}
.text-primary,
.spinner-border {
  color: var(--color-primary) !important;
}
a:hover {
  color: var(--color-primary--hover);
}
.btn-link:hover {
  color: var(--color-primary--hover) !important;
}

/* 
 * Background Colors
 */

.vertical-collpsed .vertical-menu #sidebar-menu > ul ul {
  border-left: 1px solid #fff;
}

.bg-primary,
.progress-bar,
.btn-primary,
.btn-primary:focus-visible,
.form-check-input:checked,
.page-item.active .page-link,
.nav-tabs-custom .nav-item .nav-link::after,
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active .step-number,
.vertical-collpsed .vertical-menu #sidebar-menu > ul ul,
body[data-sidebar="dark"] .vertical-menu,
body[data-sidebar="dark"]:not([data-layout="horizontal"]) .navbar-brand-box,
body[data-sidebar="dark"].vertical-collpsed
  .vertical-menu
  #sidebar-menu
  > ul
  > li:hover
  > a {
  background-color: var(--color-primary) !important;
}
.btn-primary:hover {
  background-color: var(--color-primary--hover) !important;
}
.btn-primary:disabled,
.btn-primary.disabled {
  opacity: 0.5;
  border-color: var(--color-primary) !important;
  background-color: var(--color-primary) !important;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: var(--color-primary--hover) !important;
  box-shadow: var(--color-primary--hover) !important;
}

.btn-outline-primary{
  --bs-btn-color: var(--color-primary) !important;
  --bs-btn-border-color: var(--color-primary) !important;
  --bs-btn-hover-bg: var(--color-primary) !important;
  --bs-btn-hover-border-color: var(--color-primary) !important;
  --bs-btn-active-bg: var(--color-primary) !important;
  --bs-btn-active-border-color: var(--color-primary) !important;
  --bs-btn-disabled-color: var(--color-primary--hover) !important;
  --bs-btn-disabled-border-color: var(--color-primary--hover) !important;
  
}

body:not([data-layout="horizontal"]) .navbar-brand-box {
  background-color: var(--color-primary) !important;
}

body[data-layout="horizontal"] #page-topbar {
  background-color: var(--color-primary) !important;
}

/* 
 * Border Colors
 */
.btn-primary,
.form-check-input:checked,
.page-item.active .page-link,
.twitter-bs-wizard .twitter-bs-wizard-nav .step-number {
  border-color: var(--color-primary) !important;
}
.btn-primary:hover {
  border-color: var(--color-primary--hover) !important;
}

/**
 * Filtred colors via CSS tricks
 */

body[data-layout="horizontal"] #page-topbar i,
body[data-layout="horizontal"] #page-topbar form span,
body[data-layout="horizontal"] #page-topbar form input,
body[data-layout="horizontal"] #page-topbar button a span,
body[data-layout="horizontal"] #page-topbar .header-item {
  /* mix-blend-mode: difference !important; */
  color: var(--color-body);
}
body:not([data-layout="horizontal"]) #page-topbar svg {
  top: -4px !important;
  fill: #636e75;
}
body[data-layout="horizontal"] #page-topbar svg {
  fill: var(--color-body);
  font-weight: 300 !important;
}
#sidebar-menu a i,
#sidebar-menu a span,
#sidebar-menu .has-arrow:after {
  /* filter: invert(1); */
  /* mix-blend-mode: revert; */
  color: var(--color-body);
}
#page-topbar svg {
  width: 19px;
  height: 19px;
  position: relative;
}
.btn-primary span {
  color: #fff;
  /* filter: invert(1); */
  /* mix-blend-mode: revert; */
  color: var(--color-body);
}

body[data-sidebar="dark"] #sidebar-menu ul li ul.sub-menu li a {
  color: var(--color-body) !important;
}
/* 
 * Radial chart
 */
.dissco--custom-radial-chart .apexcharts-legend-series:after {
  content: "%";
  font-size: 75%;
}
.dissco--custom-radial-chart .apexcharts-legend {
  top: inherit !important;
  left: inherit !important;
  right: 60% !important;
  bottom: 55% !important;

  text-transform: capitalize !important;
}

/* 
 * Custom .form-control style for Question-Genrator
 */

.dissco--question-genrator--input.form-control {
  height: 42px !important;
  padding-left: 20px !important;
  padding-right: 2px;
  border-radius: 9px !important;
  border-color: #bac1ff !important;
  background-color: transparent !important;
}
.dissco--question-genrator--input.form-control:disabled {
  background-color: #eff2f7 !important;
  opacity: 1;
}
.dissco--question-genrator--button {
  color: rgba(26, 29, 48, 0.7) !important;
  border-radius: 9px !important;
  background-color: rgba(217, 221, 255, 0.2) !important;
}
.dissco--question-genrator--button--md {
  height: 42px !important;
  border-color: #bac1ff !important;
}
.dissco--question-genrator--button--lg {
  height: 60px !important;
  font-size: 14px !important;
  font-weight: bolder !important;
  border: 0 !important;
}
.dissco--question-genrator--button:hover {
  background-color: #f6f6f6 !important;
}
.dissco--question-genrator--form-control.form-control {
  border: 0 !important;
  outline: 0 !important;
}
.dissco--question-generator .form-check-input,
.dissco--question-generator .form-radio-input {
  border-color: #bac1ff;
}

.dissco--question-generator .form-group {
  position: relative;
}
.dissco--question-generator .invalid-feedback {
  left: 20px;
  bottom: -8px;
  position: absolute;
  background-color: #fff;
  width: inherit !important;
}

/*
 added some space between donut-chart and it's labels
 */
.dissco--donut-chart .apexcharts-legend {
  inset: auto 0px 0 0 !important;
  padding-bottom: 10px;
}
.apexcharts-text,
.apexcharts-xaxis-label,
.apexcharts-legend-text {
  text-transform: capitalize;
}
.apexcharts-legend-marker {
  width: 10px !important;
  height: 10px !important;
  margin-right: 4px !important;
}
/*
 * this is a temporary trick. 
 *
 * find soultion here: https://github.com/facebook/create-react-app/issues/11880
 */
iframe {
  /* display: none !important; */
}

/**
 *
 */
.disable-question-box {
  opacity: 0.45;
  user-select: none !important;
}

.disable-question-box * {
  cursor: initial !important;
}

/**
 * fixed bugs: nav tab background color
 */
.nav-link:hover,
.nav-link:focus {
  color: var(--color-primary) !important;
}
.nav-tabs-custom .nav-item .nav-link.active,
.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link:not(.active) .step-number {
  color: var(--color-primary) !important;
}
.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active {
  background-color: transparent !important;
}

/**
 * fixed bugs: vector map items
 */
.legend .scale {
  white-space: nowrap;
}

/**
 * Applu custom web fonts
 */
body,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
input,
select,
textarea,
form,
button,
#sidebar-menu ul li a {
  font-family: var(--primary-font) !important;
}

/* fix logo responsive issues */
.navbar-brand-box {
  overflow: hidden;
}

/* fix: elements text colors */
.btn-primary,
.btn-primary a,
.btn-primary:hover .active.page-item .page-link,
.page-link.active,
.active > .page-link,
.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active .step-number,
body[data-sidebar="dark"] #sidebar-menu ul li a i {
  color: var(--color-body) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected{
  background-color: var(--color-primary) !important;
}

.select2-selection__option.select2-selection__option--is-selected{
  background-color: var(--color-primary) !important;
}

.select2-selection__option.select2-selection__option--is-focused{
  background-color: color-mix(in srgb, var(--color-primary) 10%, #fff);
}