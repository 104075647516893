.react-tel-input{
    font-size: font-sizes(common, large);
    font-weight: 400;

    input[type=tel]{
        padding: 4px 0px 4px 52px;
        border-color: color(black, 3);
        border-radius: 4px;
        font-size: font-sizes(common, large);
        font-weight: 400;
    }

    .flag-dropdown{
        border-color: color(black, 3);
        width: 48px;
    }
}