html[data-module='gd']{
    @import "./variables";

    @import "./components/index";
    
    @import "./pages/admin/index";
    
    @import "./pages/thirdparty/index"; 
        
    body{
        background-color: $main-bg-color;
        font-size: $font-size-base;
    }

    .main-content{
        overflow: visible;
        overflow-x: hidden;
    }

    // .topnav{
    //     margin-top: 0px;
    // }

    iframe {
        // display: none !important;
    }

    .ellipsis {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        margin: 0 auto;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    
        &.l-1{
            -webkit-line-clamp: 1;
        }
    
        &.l-2{
            -webkit-line-clamp: 2;
        }
    
        &.l-3{
            -webkit-line-clamp: 3;
        }
    }
}